<template>
    <div class="row d-flex justify-content-center mb-5 mt-5">
        <div class="col-10 mb-5">
            <h1>Iscritti</h1>
        </div>
        
        <div class="col-10" >
            <div>
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cognome</th>
                        <th scope="col">Mezzo</th>
                        <th scope="col">Targa</th>
                        <th scope="col">Tempo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(competitor, index) in this.competitors" :key="index">
                        <th scope="row">{{competitor.id}}</th>
                        <td>{{competitor.competitor.name}}</td>
                        <td>{{competitor.competitor.lastname}}</td>
                        <td>{{competitor.truck.model}}</td>
                        <td>{{competitor.truck.registration}}</td>
                        <td>{{competitor.time}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import API from "../../api.js";


export default {
  
components: { },

  data () {
    return {
       
      competitors:[
      ],
    }
  },
  mounted() {

    this.axios.get(API.getTPulling.path()).then(response => {
      this.competitors=response.data;
      console.log(response)

    }).catch(error=>{
      console.log(error)

    });

  },
}



</script>
