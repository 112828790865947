<template>
  <div class="wrapper">

    <router-view></router-view>

    <!-- HeaderNavFour section -->
    <HeaderNav></HeaderNav>
    
    <!-- HeroSectionFour section -->
    <HeroSectionFour></HeroSectionFour>


    <!-- HeaderNavFour section -->
    <TruckPulling></TruckPulling>

  </div>
</template>

<script>
import HeaderNav from '@/components/CMPM/HeaderNavCMPM.vue'
import TruckPulling from '@/components/CMPM/TruckPulling.vue'
import HeroSectionFour from '@/components/CMPM/HeroSectionCMPM.vue'

export default {
components: {
  HeaderNav,
  HeroSectionFour,
  TruckPulling,
  
}
}
</script>
