<template>
    <!-- Begin Hero Area -->
    <div class="hero-area hero-bg hero-style-4" data-bg-image="" :style="{backgroundImage: `url(${ bgImage })`}">
      <!--<a target="_blank" href="https://www.diffusione.net/"><img class="d-md-block d-none"  src="images/hero/bg/dstereo.png" style="max-width:300px; float:left; margin-top:200px; " alt="Feature Image"></a>
      <a target="_blank" href="https://www.diffusione.net/"><img class="d-md-none d-block" src="images/hero/bg/dstereo.png" style="max-width:150px; float:left; margin-top:50px; " alt="Feature Image"></a>-->
      <!--<a target="_blank" href="https://www.diffusione.net/"><img class="d-md-block d-none"  src="images/hero/bg/primiceri.png" style="max-width:300px; float:left; margin-top:500px; " alt="Feature Image"></a>
      <a target="_blank" href="https://www.diffusione.net/"><img class="d-md-none d-block" src="images/hero/bg/primiceri.png" style="max-width:200px; float:left; margin-top:50px; " alt="Feature Image"></a>-->
        <div class="container">
          <!--<a target="_blank" href="https://www.lotras.it/" style="float:right; "><img class="d-xl-block d-none" src="images/hero/bg/lotras.png" style="max-height:200px; float:right; " alt="Feature Image"></a>
          <a target="_blank" href="https://www.trasportiprimiceri.com/" style="float:left; "><img class="d-xl-block d-none" src="images/hero/bg/TFP.png" style="max-height:200px; float:right; " alt="Feature Image"></a>
          <a target="_blank" href="https://www.lotras.it/" style="float:right; "><img class="d-xl-none d-block" src="images/hero/bg/lotras.png" style="max-height:120px; float:right; " alt="Feature Image"></a>
          <a target="_blank" href="https://www.trasportiprimiceri.com/" style="float:right; "><img class="d-xl-none d-block" src="images/hero/bg/TFP.png" style="max-height:120px; float:right; " alt="Feature Image"></a>-->

          <div class="hero-item" >
                <div class="hero-content">                 
                </div>
            </div>
        </div>
    </div>
    <!-- Hero Area End Here -->
</template>

<style>

</style>
<script>
  import AnimatedImage from '@/components/Images/AnimatedImage.vue';

  export default {
    inheritAttrs:false,
    components: {

      AnimatedImage,
    },
  
    data () {
      return {
        bgImage: '/images/hero/bg/CMPM.png'
      }
    },

    mounted() {
      
    }

  }
</script>
