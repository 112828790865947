<template>
  <img
    v-observe-visibility="{ callback: isViewableNow, once: true }"
    :class="getClass()"
    />
</template>

<style>
  .animated-img-visible { visibility: visible; }
  .animated-img-invisible { visibility: hidden; }
</style>


<script>
export default {
  name: 'AnimatedImage',
  props: {
    animationClass: {
      type: String,
      default: 'animate__zoomIn',
    },
  },
  data() {
    return {
      showAnimation: false,
      animationClass: '',
    };
  },
  methods: {
    isViewableNow(isVisible, entry) {
      this.showAnimation = isVisible;
    },
    getClass() {
      const a =
        `animated-img-visible animate__animated ${this.$props.animationClass}`;
      const c = {
        [a]: this.showAnimation,
        'animated-img-visible animate__animated' : !this.showAnimation
      };
      return c;
    }
  },
};
</script>