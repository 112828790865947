const BASE_URL = "http://localhost:8000"
const VERSION = ""

const API = {
    baseUrl : BASE_URL,
    getCompetitors : {
        name: "getCompetitors",
        path() {
            return BASE_URL+VERSION+"/competitors/";
        }
        // path: "/competitors/"
    },
    getTrucks : {
        name: "getTrucks",
        path() {
            return BASE_URL+VERSION+"/trucks/";
        }
        // path: "/competitors/"
    },
    getGPrix : {
        name: "getGPrix",
        path() {
            return BASE_URL+VERSION+"/granPrixs/";
        }
        // path: "/competitors/"
    },
    getTPulling : {
        name: "getTPulling",
        path() {
            return BASE_URL+VERSION+"/truckPullings/";
        }
        // path: "/competitors/"
    },
    getBTruck : {
        name: "getBTruck",
        path() {
            return BASE_URL+VERSION+"/bestTrucks/";
        }
        // path: "/competitors/"
    },

    };

export default API;

